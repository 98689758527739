<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">


            <div v-if="show == true" id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <div class="col-md-6">
                        <h4 class="demo-title"><strong><span style="cursor: pointer" @click="VolverPanel">Panel Administrativo</span> > <span @click="IrDelegacion" style="cursor: pointer"> Delegaciones</span> > Detalle </strong></h4>
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                </mdb-row>

                <hr />

                <div style="padding: 10px"></div>

                <section>
                    <b-row style="padding: 20px">
                        <div class="col-md-3">
                            <label for="Nombre de la Delegación" style="padding-top:15px">Nombre de la Delegación</label>
                            <input type="text" id="username"  v-model="delegacion.nombre" class="form-control">
                        </div>
                        <div class="col-md-3">
                            <label for="Web de la delegación" style="padding-top:15px">Web de la Delegación</label>
                            <input type="text" id="username"  v-model="delegacion.web" class="form-control">
                        </div>

                        <div class="col-md-6">
                            <label for="Nombre de la Delegación" style="padding-top:15px">Imagen de la Delegación</label>
                            <vue-dropzone ref="myVueDropzone" id="dropzone"  @vdropzone-file-added="uploadImage" :options="dropzoneOptions"></vue-dropzone>
                        </div>

                    </b-row>
                    <b-row>
                        <div class="col-md-6">

                        </div>
                        <div class="col-md-6">
                            <b-card header="Previsualización de la imagen" :style="'width:100%;max-width:' + this.tam_img_width + 'px;'">
                                <b-card-body style="padding: 0px;">
                                        <img ref="imagen" v-bind:src="imagen"  :style="'width:100%;max-width:' + this.tam_img_width + 'px;'" />

                                </b-card-body>
                            </b-card>

                        </div>
                    </b-row>

                </section>

                <div style="padding: 10px"></div>
                <hr />

                <div style="padding: 10px"></div>
                <footer>
                    <mdb-btn style="float:left" color="primary" @click="UpdateDelegacion(1)" size="lg">Actualizar</mdb-btn>
                    <mdb-btn style="float:right" color="danger" @click="EliminarNodo" size="lg">Eliminar</mdb-btn>
                </footer>

                <div style="padding: 10px"></div>




            </div>

        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCol,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'Delegación Detalle',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbCardGroup,
            vueDropzone: vue2Dropzone,
        },
        data() {
            return {
                show: false,
                token : localStorage.getItem('token'),
                seleccionados: [],
                activo: false,
                estados : ['ON','OFF'],
                fields_usuarios: [
                    {key: 'id', label: 'Id', sortable: true },
                    {key: 'username', label: 'Nombre Usuario', sortable: true},
                    {key: 'email', label: 'Email', sortable: true},
                    {key: 'role', label: 'Rol', sortable: true},
                    {key: 'active', label: 'Activado', sortable: true},
                    {key: 'created_at', label: 'Fecha creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')} },
                ],
                delegacion : {
                    id: '',
                    nombre: '',
                    web: '',
                    imagen: '',
                    capacidad: '',
                    ruta_videos_src: '',
                    ruta_videos_originales: '',
                    ruta_imagenes: '',
                },
                esta_en_vertical: false,
                pagina_actual : 1,
                is_mobile:'',
                img_upload_title : '',
                img_upload: '',
                tam_img_width: 0,
                dropzoneOptions: {
                    url: '#',
                    thumbnailWidth: 150,
                    maxFilesize: 50,
                    maxFiles: 1,
                    headers: { "My-Awesome-Header": "header value" }
                },
                imagen : '',
            }
        },
        methods: {
            EliminarNodo()
            {
                var that = this;
                this.$swal.fire({
                    title: '¿Estás seguro que quieres borrar esta Delegación?',
                    type: 'warning',
                    showCancelButton: true,
                }).then(function(confirm) {

                    if (!confirm.dismiss) {
                        ApiService.deleteDelegacion(that.delegacion.id).then(r => {

                            console.log('eliminando')
                            console.log(r);

                            if (r.status == 200) {
                                that.$swal.fire({
                                    position: 'center',
                                    type: 'success',
                                    title: 'Delegación eliminada con éxito',
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                                that.$router.push({path: '/admin/delegaciones'});

                            } else {
                                that.$swal.fire({
                                    position: 'center',
                                    type: 'error',
                                    title: 'Error',
                                    text: 'Ha habido algún error al eliminar',
                                    showConfirmButton: true,
                                })

                            }
                        }).catch(e => {
                            that.$swal.fire({
                                position: 'center',
                                type: 'error',
                                title: 'Error',
                                text: 'Ha habido algún error al eliminar',
                                showConfirmButton: true,
                            })

                        });
                    }
                });

            },
            UpdateDelegacion( volver = 1)
            {
                ApiService.updateDelegacion(this.delegacion,this.img_upload_title,this.img_upload).then( r => {

                    console.log('creando delegacion')
                    console.log(r);

                    if(r.status == 200)
                    {
                        if(volver == 1) {
                            this.$swal.fire({
                                position: 'center',
                                type: 'success',
                                title: 'Delegación actualizada con  éxito',
                                showConfirmButton: false,
                                timer: 3000
                            })
                            this.$router.push({path: '/admin/delegaciones'});
                        }

                    }
                    else
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Error',
                            text:'Ha habido algún error al crear',
                            showConfirmButton: true,
                        })

                    }
                }).catch(e => {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Error',
                        text:'Ha habido algún error al crear',
                        showConfirmButton: true,
                    })

                });

            },
            uploadImage(e)
            {
                console.log(e);
                this.img_upload_title = e.name;
                console.log(this.img_upload_title);
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = e => {
                    this.img_upload = e.target.result;
                    this.UpdateDelegacion(0);
                    this.imagen = this.img_upload;
                }

            },
            VolverPanel()
            {
                this.$router.push({path: '/admin'});
            },
            IrDelegacion()
            {
                this.$router.push({path: '/admin/delegaciones'});
            },

            FechaSQLtoFechaES(entrada)
            {
                return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
            },
            //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
            compare(a, b) {

                var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
                var dateB = moment(b.created_at, "YYYY-MM-DD");

                if(dateA.diff(dateB) > 0) {
                    return -1;
                }
                if(dateB.diff(dateA) > 0) {
                    return 1;
                }
                return 0;

            },
            ReloadData()
            {
                ApiService.getDelegacion(this.$route.params.id).then( r => {
                    this.delegacion = r.data;

                    this.imagen = 'https://api.agustinos.tv/api/imagenes/delegacion/' + this.delegacion.id +'/' + this.token+'?w=200';
                    this.$refs.imagen.$forceUpdate();

                })


            },

        },
        mounted(){
            ApiService.getMe().then(r => {
                if(r.data.role != 'ROOT' && r.data.role != 'ADMIN' && r.data.role != 'SUPERVISOR')
                {
                    this.$swal.fire({
                        title: 'Acceso no autorizado',
                        text: 'No lo intentes, de aquí en adelante no tienes acceso',
                        type: 'error',
                        showCancelButton: false,
                    });
                    this.$router.push({path: '/'});
                }
                else {
                    this.show = true;
                }

            });

        },
        created(){

            this.tam_img_width = window.innerWidth * 0.40;
            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>
